module.exports = {
  100: {
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x64',
        rpcUrls: ['https://rpc.gnosischain.com'],
        chainName: 'Gnosis Chain',
        nativeCurrency: {
          name: 'xDAI',
          symbol: 'xDAI',
          decimals: 18
        },
        blockExplorerUrls: [
          'https://gnosisscan.io',
          'https://epor.io/tokens/SMART_CONTRACT/TOKEN_ID?network=xDai'
        ]
      }
    ]
  },
  4: {
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x4',
        rpcUrls: [
          'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
        ],
        chainName: 'Rinkeby Test Network',
        nativeCurrency: {
          name: 'RinkebyETH',
          symbol: 'RinkebyETH'
          // decimals: 18
        },
        blockExplorerUrls: [
          'https://rinkeby.etherscan.io',
          'https://testnets.opensea.io/assets/rinkeby'
        ]
      }
    ]
  }
}

import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import externalLink from '../../assets/svg/external-link.svg'

const Icon = styled.img`
  height: 10px;
  width: 10px;
  margin-left: 3px;
  align-self: center;
`

// Rename this to be text link?
const Anchor = ({
  children = null,
  url,
  name,
  external = false,
  markdown = false
}) => {
  // This case is when in the page there is a Markdown document with links inside the same page
  if (markdown && url && url.startsWith('#')) {
    return (
      <a
        href={''}
        rel='noopener noreferrer'
        target='_blank'
        title={name}
        onClick={(event) => {
          event.preventDefault()
          const elements = document.querySelectorAll('h2')
          const element = Array.from(elements).find((element) => {
            const separator = ' '
            // Create an array of string using a space as separator
            const nameStrings = name[0].toLowerCase().split(separator)
            const htmlElementStrings = element.innerHTML
              .toLowerCase()
              .split(separator)
            // Search all the matches between the received name for the anchor and the current h2 element
            const matches = nameStrings.filter(
              (value) => htmlElementStrings.indexOf(value) !== -1
            )
            // If the matching words are enough, the element to which bring the view into has been found
            return matches.length >= htmlElementStrings.length / 2
              ? element
              : undefined
          })
          if (element) element.scrollIntoView()
        }}
      >
        {children || name}
        {external && <Icon src={externalLink} alt={name} />}
      </a>
    )
  }
  return (
    <a href={url} rel='noopener noreferrer' target='_blank' title={name}>
      {children || name}
      {external && <Icon src={externalLink} alt={name} />}
    </a>
  )
}

export default Anchor

Anchor.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array.isRequired
  ]),
  external: PropTypes.bool,
  markdown: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ]),
  url: PropTypes.string.isRequired
}

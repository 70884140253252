import PropTypes from 'prop-types'
import styled from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from '../../styles/ColorPalette'
import { ICONS } from '../../styles/IconLibrary'

const IconStyle = styled.div`
  color: ${({ color }) => {
    if (color.startsWith('#')) {
      return color
    }
    return DEFAULT_COLOR_PALETTE.palette[color]?.primary
  }};
  display: inline-block;
  svg {
    display: block;
  }
`

const Icon = ({ name, size = 18, color = 'white', ...props }) => {
  const SvgIcon = ICONS[name]?.icon

  if (SvgIcon) {
    return (
      <IconStyle data-testid='icon-style' color={color} {...props}>
        <SvgIcon data-testid='icon' width={size} height={size} />
      </IconStyle>
    )
  }
  return null
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(ICONS)),
  size: PropTypes.number,
  color: PropTypes.string
}

export default Icon

import styled, { css } from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from '../../../styles/ColorPalette'
import { SIZE } from '../../../styles/Size'

export const Container = styled.footer`
  font-family: 'Fraktion Sans Regular';

  @media screen and (max-width: ${SIZE.mobile}) {
    flex-direction: column;
    padding: 0;
    margin: 0;
    align-items: stretch;

    img[name='footer-logo'] {
      display: none;
    }
  }
`

export const Icons = styled.div`
  a {
    color: ${DEFAULT_COLOR_PALETTE.grayScales.text.primary};
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    justify-content: center;
    align-items: center;
    height: 112px;
  }
`

export const ColumnHeader = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${DEFAULT_COLOR_PALETTE.grayScales.text.primary};
  margin-bottom: 32px;

  @media screen and (max-width: ${SIZE.mobile}) {
    display: none;
  }
`

export const ColumnLine = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 36px;
  color: ${DEFAULT_COLOR_PALETTE.grayScales.text.primary};

  svg {
    display: none;
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 64px;
    line-height: 64px;
    border-bottom: 0.5px solid ${DEFAULT_COLOR_PALETTE.grayScales.text.disabled};

    svg {
      display: block;
    }
  }
`

export const ColumnList = styled.ul`
  list-style: none;
  margin: 0 26px;

  @media screen and (max-width: ${SIZE.mobile}) {
    transition: max-height 0.25s ease-in-out;
    cursor: pointer;

    ${({ expanded }) => {
      if (!expanded) {
        return css`
          max-height: 0px;
          overflow: hidden;
        `
      }
      return css`
        max-height: 100vh;
      `
    }}
  }
`

export const ColumnListItem = styled.li`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  a {
    color: ${DEFAULT_COLOR_PALETTE.grayScales.text.primary};
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    text-align: center;
    line-height: 31.5px;
  }
`

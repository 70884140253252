import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from './ColorPalette'

const { grayScales, palette } = DEFAULT_COLOR_PALETTE

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Fraktion Mono Bold';
    src: url(https://fraktion.s3.us-east-2.amazonaws.com/PPFraktionMono-Bold.woff) format('woff');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Fraktion Mono Regular';
    src: url(https://fraktion.s3.us-east-2.amazonaws.com/PPFraktionMono-Regular.woff) format('woff');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Fraktion Sans Regular';
    src: url(https://fraktion.s3.us-east-2.amazonaws.com/PPFraktionSans-Regular.woff) format('woff');
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Fraktion Sans Medium';
    src: url(https://fraktion.s3.us-east-2.amazonaws.com/PPFraktionSans-Medium.woff) format('woff');
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Fraktion Sans Semi-Bold';
    src: url(https://fraktion.s3.us-east-2.amazonaws.com/PPFraktionSans-Semibold.woff) format('woff');
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Fraktion Sans Bold';
    src: url(https://fraktion.s3.us-east-2.amazonaws.com/PPFraktionSans-Bold.woff) format('woff');
    font-display: swap;
    font-style: normal;
  }
  
  body {
    margin: 0;
    padding: 0;
    position: relative;
    font-family: 'Fraktion Sans Regular', sans-serif;
    background-color: ${grayScales.background.primary};
    width: 100vw;
    height: 100vh;

    #root {
      position: relative;
      width: 100vw;
      height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  header {
    background-color: ${grayScales.background.secondary};
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
    box-sizing: border-box;
    margin: 1em auto 3em;
  }

  footer {
    padding: 2em;
    box-sizing: border-box;
    background-color: ${grayScales.background.secondary};
    margin: 1em auto;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    > img {
      // Probably should be link to top?
      user-select: none;
      height: 175px;
      width: 175px;
      border-radius: 15px;

    }

    > div:last-of-type {
      div {
        display: flex;
        flex-direction: row;
        gap: 1em;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Fraktion Mono Regular', sofia-pro, sans-serif;
    font-style: normal;
    color: ${grayScales.text.primary};

    &::selection {
      background-color: ${palette.purple.primary};
    }
  }

  h1 {
    font-size: 2.25em;
    margin-bottom: 0;
  }

  p {
    font-family: 'Fraktion Sans Bold', sans-serif;
    padding-bottom: 0;
    color: ${grayScales.text.primary};
    padding: 0;
    margin: 0;

    &::selection {
      background-color: ${palette.purple.primary};
    }
  }

  button {
    width: fit-content;
    font-family: 'Fraktion Sans Bold', sans-serif;
    cursor: pointer;
    display: flex;
    user-select: none;
    box-sizing: border-box;
    flex-direction: row;
    // Aligns any icons with the text to sit flush
    align-items: self-end;
    gap: 0.5em;
    border-radius: 50px;
    vertical-align: top;
    font-size: 1rem;
    // Padding is uneven here because of top padding on font face
    padding: 0.6em 1em 0.65em;
    user-select: none;
    line-height: 1.2;

    img {
      height: 1rem;
      width: 1rem;
    }
  }

  a {
    font-weight: 300;
    font-style: normal;
    color: ${palette.purple.primary};
    text-decoration: none;
    font-family: 'Fraktion Sans Bold', sans-serif;

    &:hover {
      text-decoration: underline;
    }

    &::selection {
      background-color: ${palette.purple.primary};
      color: ${grayScales.text.primary};
    }
  }

  code {
    background-color: ${transparentize(0.85, palette.purple.primary)};
    font-size: 0.8em;
    padding: 2px;
    border-radius: 5px;
    font-family: 'Fraktion Mono Regular';
    color: ${palette.purple.primary};
    font-weight: 400;
    font-style: normal;
  }

  pre {
    code {
      padding: 0;
      color: hsl(60, 4%, 80%);
      background-color: transparent;
      border: none;
    }
  }

  ul, ol {
    list-style-position: inside;
    padding: 0;

    > * {
      color: ${grayScales.text.primary};
    }
  }

  hr {
    color: #292929;
  }
`

export default GlobalStyle

import styled, { css } from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from '../../../styles/ColorPalette'
import { SIZE } from '../../../styles/Size'

const { background, text } = DEFAULT_COLOR_PALETTE.grayScales

export const Navbar = styled.nav`
  height: 114px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${background.secondary};
  color: ${text.primary};
  border-radius: 20px;
  padding: 24px;
  box-sizing: border-box;
  z-index: 10;
  margin-bottom: 64px;

  @media screen and (max-width: ${SIZE.mobile}) {
    height: 57px;
    padding: 12px 16px;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`

export const NavbarMenu = styled.div`
  margin-left: auto;
  ul {
    display: flex;
    align-items: center;
    gap: 4.5rem;
    li {
      list-style-type: none;
    }
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    ${({ expanded }) => css`
      display: ${expanded ? 'block' : 'none'};
    `}
    ul {
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: stretch;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 64px);
      height: calc(100vh - 10px);
      background-color: ${background.tertiary};
      margin: -32px;
      li {
        height: 42px;
        border-bottom: 0.5px solid ${background.disabled};
        a {
          font-size: 12px;
          line-height: 42px;
          display: grid;
          grid-template-columns: 70px 1fr;
          [role='img'] > * {
            margin: 0 auto;
            height: 16px;
            width: 16px;
          }
          label {
            grid-column: 2 / 2;
          }
        }
      }
    }
  }
`

export const Brand = styled.span`
  height: 30px;
  width: auto;
  position: relative;

  @media screen and (max-width: ${SIZE.mobile}) {
    order: 2;
  }
`

export const Burger = styled.button`
  height: 20px;
  width: 20px;
  border: 0;
  color: ${text.primary};
  background-color: transparent;
  display: block;
  box-sizing: content-box;
  display: none;
  padding: 0;

  @media screen and (max-width: ${SIZE.mobile}) {
    display: block;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  display: none;
  background-color: transparent;
  z-index: 10;
  color: ${text.primary};
  @media screen and (max-width: ${SIZE.mobile}) {
    display: block;
  }
  svg,
  img {
    width: 16px;
    height: 16px;
  }
`

export const AuthStatusContainer = styled.li`
  @media screen and (max-width: ${SIZE.mobile}) {
    order: -1;
    button {
      color: ${text.primary};
      background-color: ${background.disabled};
      width: calc(100% - 32px);
      margin: 0 auto;
      font-size: 12px;
    }
  }
`

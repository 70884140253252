import { useLayoutEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

/**
 * It creates a new NodeElement and it appends it to body.
 *
 * @param {string} wrapperId
 * @returns NodeElement
 */
function createWrapperAndAppendToBody(wrapperId) {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId)
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

const Portal = ({ children, wrapperId = 'portal-wrapper' }) => {
  const [wrapperElement, setWrapperElement] = useState(null)

  // We handle dynamic changing wrapperId prop
  // Please note: we need to use `useLayoutEffect` as we are modifying the DOM
  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId)
    let systemCreated = false
    // If element is not found with wrapperId, create and append to body
    if (!element) {
      systemCreated = true
      element = createWrapperAndAppendToBody(wrapperId)
    }
    setWrapperElement(element)

    // If we created a new element, we should remove it from the DOM
    return () => {
      if (systemCreated && element.parentNode) {
        // delete the programmatically created element
        element.parentNode.removeChild(element)
      }
    }
  }, [wrapperId])

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null

  // Create a portal
  return createPortal(children, wrapperElement)

  // return ReactDOM.createPortal(
  // 	<AnimatePresence>
  // 		{modalSeen && (
  // 			<Overlay>
  // 				{children}
  // 			</Overlay>
  // 		)}
  // 	</AnimatePresence>,
  // 	document.getElementById('portal')
  // )
}

export default Portal

Portal.propTypes = {
  children: PropTypes.node,
  wrapperId: PropTypes.string
}

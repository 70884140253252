import { rest } from 'msw'

import { baseURL } from '../../api/http-client'

export const NONCE = 'HxuxIg2xVhWSMsqv3'
export const ADDRESS = '0x8737A713E76Dc9ffBB3bd722aBBf8eaA55D661D3'
export const BAD_ADDRESS = '0x6a33Edf9371E92f9643DBfaFeD007cB9fb5235c7'

export const getSessionPath = `${baseURL}/auth`

export const getAuthenticatedSessionHandler = rest.get(
  getSessionPath,
  async (req, res, ctx) => {
    return res(
      ctx.json({
        domain: 'localhost:3000',
        address: ADDRESS,
        statement: 'Sign in with Ethereum to the Education DAO.',
        uri: 'http://localhost:3000',
        version: '1',
        nonce: NONCE,
        issuedAt: new Date().toISOString(),
        chainId: 100
      })
    )
  }
)

const getNonceHandler = rest.get(`${baseURL}/nonce`, async (req, res, ctx) => {
  return res(ctx.json({ nonce: NONCE }))
})

const getNonceHandlerAuth = rest.get(
  `${getSessionPath}/nonce`,
  async (req, res, ctx) => {
    return res(ctx.json({ nonce: NONCE }))
  }
)

const postVerifyHandler = rest.post(
  `${getSessionPath}/verify`,
  async (req, res, ctx) => {
    const {
      message: { address }
    } = await req.json()
    if (address === BAD_ADDRESS) {
      return res(ctx.status(403))
    }
    return res(
      ctx.json({
        domain: 'localhost:3000',
        address,
        statement: 'Sign in with Ethereum to the Education DAO.',
        uri: 'http://localhost:3000',
        version: '1',
        nonce: NONCE,
        issuedAt: new Date().toISOString(),
        chainId: 100
      })
    )
  }
)

const deleteSessionHandler = rest.delete(
  `${getSessionPath}`,
  async (req, res, ctx) => {
    return res(ctx.json({}))
  }
)

export const getUnauthenticatedSessionHandler = rest.get(
  getSessionPath,
  async (req, res, ctx) => {
    return res(ctx.json({}))
  }
)

export const handlers = [
  getAuthenticatedSessionHandler,
  getNonceHandler,
  getNonceHandlerAuth,
  postVerifyHandler,
  deleteSessionHandler
]

import { handlers as authHandlers } from './auth'
import { handlers as githubHandlers } from './github'
import { handlers as lessonsHandlers } from './lessons'
import { handlers as modulesHandlers } from './modules'
import { handlers as quizHandlers } from './quiz'

export const handlers = [
  ...authHandlers,
  ...lessonsHandlers,
  ...modulesHandlers,
  ...githubHandlers,
  ...quizHandlers
]

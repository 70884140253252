import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

const AuthenticationFailed = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='red'>
      <h3>Authentication Failed</h3>
      <p>
        Something went wrong and we couldn&apos;t log you in. Try again later!
      </p>
      <Button
        colorscheme='white'
        variant='secondary'
        label='Close'
        handleClick={handleClose}
      />
    </Modal>
  )
}
AuthenticationFailed.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default AuthenticationFailed

import { motion } from 'framer-motion'
import { transparentize } from 'polished'
import styled from 'styled-components'

import {
  DEFAULT_COLOR_PALETTE,
  VARIANT_COLOR_PALETTE
} from '../../../styles/ColorPalette'
import { fadeInWithDelay } from '../../../styles/FramerVariants'
import { SIZE } from '../../../styles/Size'
import IconButton from '../../atoms/IconButton'

const { grayScales, palette } = DEFAULT_COLOR_PALETTE

export const Overlay = styled(motion.div).attrs(() => ({
  key: 'portal',
  animate: 'animate',
  exit: 'exit',
  initial: 'initial',
  tabIndex: -1,
  variants: fadeInWithDelay
}))`
  background-color: ${transparentize(0.25, grayScales.background.primary)};
  display: grid;
  overflow-y: hidden;
  position: absolute;
  place-items: center;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: 100;
  height: 100vh;
`

export const Container = styled(motion.dialog)`
  height: fit-content;
  width: 450px;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: ${grayScales.background.secondary};
  color: ${grayScales.text.primary};
  border-radius: 20px;
  border: 2px solid ${grayScales.background.primary};
  position: relative;
  // overflow: hidden;
  ${'' /* Can some1 blz esplain y */}
  ${'' /* color gives the dialog element a border */}
	${'' /* but then border: none removes it */}
	${'' /* Documenting this to prove CSS is feral */}
  z-index: 200;
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${SIZE.mobile}) {
    width: 400px;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 700;
    margin: 0 0 24px;
    text-align: center;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
    margin: 0 0 24px;
    text-align: center;
  }
  button,
  a {
    width: 270px;
    margin: 0 0 24px;
  }
`

export const Banner = styled.div`
  height: 40px;
  position: relative;
  color: ${({ variant }) => palette[VARIANT_COLOR_PALETTE[variant]]?.primary};
  width: 100%;
  svg {
    --first-fill: ${({ variant }) => PALETTE[variant]?.first};
    --second-fill: ${({ variant }) => PALETTE[variant]?.second};
    --third-fill: ${({ variant }) => PALETTE[variant]?.third};
    --fourth-fill: ${({ variant }) => PALETTE[variant]?.fourth};
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px !important;
  & > div {
    position: static;
    width: 24px;
    height: 24px;
  }
`

function getColor(name) {
  return palette[name]?.primary
}

const yellow = {
  first: getColor('yellow'),
  second: getColor('default'),
  third: getColor('white'),
  fourth: getColor('default')
}

const white = {
  first: getColor('white'),
  second: getColor('purple'),
  third: getColor('yellow'),
  fourth: getColor('yellow')
}

const purple = {
  first: getColor('purple'),
  second: getColor('default'),
  third: getColor('white'),
  fourth: getColor('purple')
}

const orange = {
  first: getColor('orange'),
  second: getColor('default'),
  third: getColor('white'),
  fourth: getColor('orange')
}

const blue = {
  first: getColor('blue'),
  second: getColor('default'),
  third: getColor('white'),
  fourth: getColor('blue')
}

const skyBlue = {
  first: getColor('skyBlue'),
  second: getColor('default'),
  third: getColor('white'),
  fourth: getColor('skyBlue')
}

const red = {
  first: getColor('red'),
  second: getColor('default'),
  third: getColor('white'),
  fourth: getColor('red')
}

export const PALETTE = { yellow, white, purple, orange, blue, skyBlue, red }

import React from 'react'

import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
  ColumnHeader,
  ColumnLine,
  ColumnList,
  ColumnListItem,
  Container,
  Icons
} from './Footer.style'
import { SOCIAL_LINKS } from './SocialLinks'

import logo from '../../../assets/brand/purple_bg.svg'
import { ReactComponent as ChevronLeft } from '../../../assets/svg/chevron-left.svg'
import ExternalLink from '../../atoms/ExternalLink'

const Icon = React.forwardRef((props, ref) => (
  <ChevronLeft {...props} ref={ref} />
))
Icon.displayName = 'ExpandIcon'
const AnimatedIcon = motion(Icon)

const FooterAccordion = ({ title, items = [] }) => {
  const [expanded, setExpanded] = React.useState(false)

  if (!items.length) {
    return <ColumnLine>{title}</ColumnLine>
  }
  return (
    <>
      <ColumnLine
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: 'pointer' }}
      >
        {title}
        <AnimatedIcon animate={{ rotate: expanded ? 180 : -90 }} />
      </ColumnLine>
      <ColumnList expanded={expanded}>
        {items.map((item) => (
          <ColumnListItem key={item.key}>{item}</ColumnListItem>
        ))}
      </ColumnList>
    </>
  )
}
FooterAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.node.isRequired)
}

const Footer = () => {
  return (
    <Container>
      <img name='footer-logo' src={logo} alt='Education DAO' />
      {/* Sitemap */}
      <div>
        <ColumnHeader>About</ColumnHeader>
        <ColumnLine>About Education DAO</ColumnLine>
        <FooterAccordion
          title='Decentralized Mechanics'
          items={[
            <Link key='course' to='/course-interface'>
              Course Interface
            </Link>,
            <Link key='bootcamp' to='/bootcamp-nft'>
              Bootcamp NFT
            </Link>,
            <Link key='gitpoap' to='/gitpoap'>
              GitPOAP
            </Link>,
            <Link key='module' to='/module-nfts'>
              Module NFTs
            </Link>,
            <Link key='govern' to='/govern-contributions'>
              Govrn Contributions
            </Link>,
            <Link key='noble' to='/noble-discord'>
              Noble &lt;&gt; Discord
            </Link>
          ]}
        />
        <ColumnLine>Documentation</ColumnLine>
      </div>
      <div>
        <ColumnHeader>Lessons</ColumnHeader>
        <ColumnLine>Our Course</ColumnLine>
        <ColumnLine>Support</ColumnLine>
        <ColumnLine>Contribute</ColumnLine>
        <ColumnLine>FAQ</ColumnLine>
      </div>
      {/* <div>
        <ColumnHeader>Newsletter</ColumnHeader>
        <Input type='text' placeholder='youremail@that.com' icon={email} />
        <p
          style={{
            fontSize: '12px',
            fontFamily: 'Fraktion Sans Regular, sans-serif',
            display: 'inline-block',
            marginTop: '1rem',
            width: '15rem'
          }}
        >
          Sign up to our newsletter for news on the web3, governance, and
          events!
        </p>
        <Button
          label='Sign up'
          variant='primary'
          colorscheme='purple'
          icon='send'
          style={{ marginTop: '1rem' }}
        />
      </div> */}
      {/* Social Media Links */}
      <div>
        <ColumnHeader>Contact Us</ColumnHeader>
        <Icons>
          {SOCIAL_LINKS.map(({ href, icon }) => (
            <ExternalLink key={href} href={href}>
              {icon}
            </ExternalLink>
          ))}
        </Icons>
      </div>
    </Container>
  )
}

export default Footer

import Avvvatars from 'avvvatars-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const AvatarSizes = {
  XS: 16,
  S: 32,
  M: 40,
  L: 64,
  XL: 128,
  XXL: 160
}

const Wrapper = styled.div`
  border-radius: 50%;
  padding: 2px;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  img {
    user-select: none;
    border-radius: 50%;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
  }
`

export default function Avatar({
  address,
  avatar,
  size = AvatarSizes.M,
  ...props
}) {
  return (
    <Wrapper {...props} size={size} data-testid='avatar'>
      {avatar ? (
        <img src={avatar} alt='ENS Avatar' />
      ) : (
        <Avvvatars value={address} style='shape' size={size} />
      )}
    </Wrapper>
  )
}

Avatar.propTypes = {
  address: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  size: PropTypes.number
}

import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

const GenericError = ({
  isOpen,
  handleClose,
  message = 'We couldn’t complete your request. Try again later.'
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='red'>
      <h3>Hmm, something went wrong!</h3>
      <p>{message}</p>
      <Button
        variant='secondary'
        colorscheme='white'
        label='Close'
        handleClick={handleClose}
      />
    </Modal>
  )
}
GenericError.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  message: PropTypes.string
}

export default GenericError

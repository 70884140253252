import { transparentize } from 'polished'

import { DEFAULT_COLOR_PALETTE } from './ColorPalette'
const { grayScales, palette } = DEFAULT_COLOR_PALETTE

// Not implemented
export const hoverVariants = (variant, colorscheme) => {
  switch (colorscheme) {
    case 'default':
      switch (variant) {
        case 'primary':
          return {
            backgroundColor: transparentize(0.4, grayScales.background.primary),
            color: transparentize(0.4, grayScales.text.primary)
          }
        case 'secondary':
          return {
            borderColor: transparentize(0.4, grayScales.background.primary),
            color: transparentize(0.4, grayScales.background.primary)
          }
        default:
          return { color: transparentize(0.4, grayScales.background.primary) }
      }
    default:
      switch (variant) {
        case 'primary':
          return { backgroundColor: palette[colorscheme].hover }
        case 'secondary':
          return {
            borderColor: palette[colorscheme].hover,
            color: palette[colorscheme].hover
          }
        default:
          return { color: palette[colorscheme].hover }
      }
  }
}

export const pressVariants = (variant, colorscheme) => {
  switch (colorscheme) {
    case 'default':
      switch (variant) {
        case 'primary':
          return {
            backgroundColor: transparentize(0.1, grayScales.background.primary),
            color: transparentize(0.1, grayScales.text.primary)
          }
        case 'secondary':
          return {
            borderColor: transparentize(0.1, grayScales.background.primary),
            color: transparentize(0.1, grayScales.background.primary)
          }
        default:
          return { color: transparentize(0.1, grayScales.background.primary) }
      }
    default:
      switch (variant) {
        case 'primary':
          return { backgroundColor: palette[colorscheme].pressed }
        case 'secondary':
          return {
            borderColor: palette[colorscheme].pressed,
            color: palette[colorscheme].pressed
          }
        default:
          return { color: palette[colorscheme].pressed }
      }
  }
}

export const fadeInWithDelay = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.25 } },
  exit: { opacity: 0, transition: { duration: 0.25 } }
}

export const dropIn = {
  hidden: {
    y: '-50vh',
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  },
  exit: {
    y: '20vh',
    opacity: 0,
    transition: {
      duration: 0.5,
      type: 'spring',
      damping: 25,
      stiffness: 500
    }
  }
}

export const details = {
  expanded: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 }
}

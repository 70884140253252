import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

// Recycle this or turn into its own component
const Wrapper = styled.a`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  img,
  svg {
    user-select: none;
  }
`

export default function ExternalLink({ href, children, ...props }) {
  return (
    <Wrapper {...props} href={href} rel='noopener noreferrer' target='_blank'>
      {children}
    </Wrapper>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

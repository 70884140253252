import { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

function ScrollToTop({ children }) {
  const { pathname } = useLocation()
  useEffect(() => {
    const container = document.getElementById('root')
    container.scroll({ top: 0, left: 0 })
    return () => {}
  }, [pathname])

  return <>{children}</>
}

ScrollToTop.propTypes = {
  children: PropTypes.node
}
export default ScrollToTop

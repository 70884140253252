import { useState } from 'react'

import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import useNotification, { NotificationTypes } from '../../hooks/useNotification'
import { isMobile } from '../../utils'
import Wrapper from '../atoms/Wrapper'
import Footer from '../organisms/Footer'
import Header from '../organisms/Header'

const Main = styled.main`
  flex: 1 0 0;
`

const Layout = () => {
  const [notifyMobile, setNotifyMobile] = useState(isMobile())
  const { notify } = useNotification()

  if (notifyMobile) {
    notify(NotificationTypes.SuggestMobile)
    setNotifyMobile(false)
  }

  return (
    <Wrapper>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Wrapper>
  )
}

export default Layout

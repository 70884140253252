import React from 'react'

import { motion } from 'framer-motion'
import { transparentize, cover } from 'polished'
import styled from 'styled-components'

import loaderSVG from '../../assets/svg/loader.svg'
import { DEFAULT_COLOR_PALETTE } from '../../styles/ColorPalette'
import { fadeInWithDelay } from '../../styles/FramerVariants'

const { grayScales } = DEFAULT_COLOR_PALETTE

const Overlay = styled(motion.div).attrs(() => ({
  key: 'portal',
  animate: 'animate',
  exit: 'exit',
  initial: 'initial',
  tabIndex: -1,
  variants: fadeInWithDelay
}))`
  ${cover}
  background-color: ${transparentize(0.25, grayScales.background.primary)};
  display: grid;
  overflow-y: hidden;
  position: absolute;
  place-items: center;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: 100;
  @media (min-width: 768px) {
    height: 100vh;
  }
`

const Wrap = styled.div`
  height: 300px;
  width: 300px;
`

export const LoaderWithOverlay = () => {
  return (
    <Overlay>
      <Wrap>
        <img src={loaderSVG} alt='Loader' />
      </Wrap>
    </Overlay>
  )
}

export const Loader = () => {
  return (
    <Wrap>
      <img src={loaderSVG} alt='Loader' />
    </Wrap>
  )
}

import { httpClient } from './http-client'

/**
 * Fetch a nonce to sign the SIWE message
 *
 * @returns {Promise.<string>} nonce
 */
export async function getNonce() {
  const res = await httpClient.get('/auth/nonce')
  const { nonce } = res.data
  return nonce
}

/**
 * Sign in with Etherium
 *
 * @param message The SIWE message used to signin
 * @param signature The signature of the SIWE message
 */
export async function signin(message, signature) {
  try {
    const res = await httpClient.post('/auth/verify', { message, signature })
    console.log('Signed in')
    return res.data
  } catch (err) {
    console.error('Failed to sign in')
    throw err
  }
}

export async function signout() {
  try {
    await httpClient.delete('/auth')
    return true
  } catch (err) {
    console.error('Failed to signout: ' + err.message)
    return false
  }
}

export async function checkSession() {
  const res = await httpClient.get('/auth')
  return res.data
}

import { useState } from 'react'

import PropTypes from 'prop-types'
import { useSwitchNetwork } from 'wagmi'

import networkConfigurations from '../../network-configurations'
import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

const ChangeNetwork = ({ isOpen, handleClose }) => {
  const { switchNetworkAsync } = useSwitchNetwork()
  const [error, setError] = useState(null)
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  const handleClick = async () => {
    try {
      await switchNetworkAsync?.(chainId)
      handleClose()
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  const configureNetwork = () => {
    window.ethereum.request(networkConfigurations[chainId])
    handleClose()
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='blue'>
      {!error && (
        <>
          <h3>Hmm, it looks like you are on the wrong chain</h3>
          <p>
            Education DAO requires you to be on{' '}
            {process.env.REACT_APP_CHAIN_NAME}.
          </p>
          <Button
            variant='primary'
            colorscheme='blue'
            label='Change Network'
            icon='metamask'
            handleClick={handleClick}
          />
        </>
      )}
      {error && (
        <>
          <h3>
            Oops, looks like you don’t have {process.env.REACT_APP_CHAIN_NAME}{' '}
            configured in your MetaMask
          </h3>
          <p>
            Education DAO requires you to be on{' '}
            {process.env.REACT_APP_CHAIN_NAME}.
          </p>
          <Button
            variant='primary'
            colorscheme='orange'
            label='Configure Network'
            icon='metamask'
            handleClick={configureNetwork}
          />
        </>
      )}
      <Button
        variant='secondary'
        colorscheme='white'
        label='Close'
        handleClick={handleClose}
      />
    </Modal>
  )
}
ChangeNetwork.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default ChangeNetwork

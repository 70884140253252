import { css } from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from './ColorPalette'

const { grayScales, palette } = DEFAULT_COLOR_PALETTE

export const buttonStyles = () => css`
  &:disabled {
    color: ${({ colorscheme }) =>
      colorscheme === 'grey'
        ? grayScales.text.tertiary
        : grayScales.text.disabled};
    cursor: not-allowed;
    user-select: none;
  }
  
  &:hover {
    background-color: ${({ colorscheme }) =>
      palette[colorscheme].background.hover};
  }

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ colorscheme }) =>
        colorscheme === 'default'
          ? grayScales.text.primary
          : grayScales.text.tertiary};
      background-color: ${({ colorscheme }) => palette[colorscheme].primary};
    `}
  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      border: 2px solid ${({ colorscheme }) => palette[colorscheme].primary};
      color: ${({ colorscheme }) => palette[colorscheme].primary};
      background-color: ${({ colorscheme }) =>
        palette[colorscheme].hover.default};
    `}
  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      color: ${({ colorscheme }) => {
        if (colorscheme === 'default') {
          return grayScales.text.tertiary
        }
        if (colorscheme === 'grey') {
          return palette[colorscheme].primary
        }
        return grayScales.text.primary
      }};
      background-color: ${({ colorscheme }) =>
        palette[colorscheme].hover.default};
      border: ${({ colorscheme }) =>
        colorscheme !== 'default' &&
        colorscheme !== 'white' &&
        colorscheme !== 'grey'
          ? `2px solid ${palette[colorscheme].background.default}`
          : ''};
    `}
}
`

import { HashRouter } from 'react-router-dom'

import Routes from './components/Routes'
import ScrollToTop from './components/templates/ScrollToTop'
import GlobalStyle from './styles/GlobalStyle'

const App = () => {
  return (
    <>
      <GlobalStyle />
      <HashRouter>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </HashRouter>
    </>
  )
}

export default App

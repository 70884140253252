import React from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Container } from './NavLink.style'

const NavLink = ({ path, active = false, desktop = true, children }) => {
  const external = path.startsWith('http')
  return (
    <Container active={active} disabled={path === ''} desktop={desktop}>
      {external ? (
        <a href={path} target='_blank' rel='noreferrer'>
          {children}
        </a>
      ) : (
        <Link to={path}>{children}</Link>
      )}
    </Container>
  )
}

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  desktop: PropTypes.bool
}

export default NavLink

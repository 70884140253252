import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import LinkButton from '../atoms/LinkButton'
import Modal from '../organisms/Modal'

const MetaMaskRequired = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='purple'>
      <h3>Hmm, it looks like you don’t have a browser wallet</h3>
      <p>
        You&apos;ll need a browser wallet like MetaMask to access modules and
        complete lessons.
      </p>
      <LinkButton
        variant='primary'
        colorscheme='purple'
        label='Install MetaMask'
        icon='metamask'
        href='https://metamask.io/'
      />
      <Button
        variant='secondary'
        colorscheme='white'
        label='Cancel'
        handleClick={handleClose}
      />
    </Modal>
  )
}
MetaMaskRequired.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default MetaMaskRequired

import React from 'react'

import PropTypes from 'prop-types'

import { Container, StyledLink } from './Account.style'

import { formatAddress } from '../../../utils'
import Avatar, { AvatarSizes } from '../Avatar'

const Account = ({ address, ens, avatar }) => {
  return (
    <Container>
      <Avatar
        name='avatar'
        address={address}
        avatar={avatar}
        size={AvatarSizes.S}
      />
      <StyledLink to='/dashboard' data-testid='account-link'>
        <label>User Handle</label>
        <p>{ens || formatAddress(address)}</p>
      </StyledLink>
    </Container>
  )
}

export default Account

Account.propTypes = {
  address: PropTypes.string.isRequired,
  ens: PropTypes.string,
  avatar: PropTypes.string
}

export default [
  {
    id: 'q1-A',
    description: 'String is a primitive type'
  },
  {
    id: 'q1-C',
    description: 'Number is a primitive type'
  },
  {
    id: 'q1-D',
    description: 'Undefined is a primitive type'
  },
  {
    id: 'q1-E',
    description: 'Null is a primitive type'
  },
  {
    id: 'q1-F',
    description: 'Boolean is a primitive type'
  },
  {
    id: 'q2-A',
    description: 'Atomicity is the A in ACID transaction properties'
  },
  {
    id: 'q2-B',
    description: 'Durability is the D in ACID transaction properties'
  },
  {
    id: 'q2-C',
    description: 'Consistency is the C in ACID transaction properties'
  },
  {
    id: 'q3-A',
    description: 'In a distribuited ledger there is no central authority'
  },
  {
    id: 'q4-B',
    description:
      'Consensus for blockchain is a procedure in which the peers of a Blockchain network reach agreement about the present state of the data in the network. Through this, consensus algorithms establish reliability and trust in the Blockchain network.'
  }
]

// const answers = questions
//   .flatMap((q) => q.answers.map((a) => ({ ...a, questionId: q.id })))
//   .map((answer) => {
//     const correct = correctAnswers.find((c) => c.id === answer.id)
//     return { ...answer, ...correct, correct: Boolean(correct) }
//   })

// export default answers

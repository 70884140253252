import React from 'react'

import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from './Icon'

import { ICONS } from '../../styles/IconLibrary'
import { buttonStyles } from '../../styles/Shared'

const Anchor = styled(motion.a)`
  background-color: transparent;
  border: none;
  border-radius: 25px;
  width: fit-content;
  font-family: 'Fraktion Sans Bold', sans-serif;
  cursor: pointer;
  display: flex;
  user-select: none;
  box-sizing: border-box;
  flex-direction: row;
  align-items: self-end;
  justify-content: center;
  gap: 0.5em;
  border-radius: 50px;
  vertical-align: top;
  padding: 0.6em 1em 0.65em;
  line-height: 1.2;
  text-decoration: none;
  img {
    height: 1.1em;
    width: 1.1em;
  }
  ${buttonStyles}
`

const LinkButton = ({
  icon,
  label,
  href,
  variant,
  colorscheme = null,
  isDisabled
}) => {
  return (
    <Anchor
      variant={variant}
      href={href}
      colorscheme={colorscheme}
      whileTap={!isDisabled && { opacity: 0.4 }}
      whileHover={!isDisabled && { opacity: 0.7 }}
      target='_blank'
    >
      {ICONS[icon] && <Icon name={icon} />}
      {label}
    </Anchor>
  )
}

export default LinkButton

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', null]),
  icon: PropTypes.oneOf(['metamask', 'discord']),
  colorscheme: PropTypes.oneOf([
    'default',
    'red',
    'blue',
    'purple',
    'green',
    'orange',
    'yellow'
  ]),
  isDisabled: PropTypes.bool
}

export default [
  { questionId: 'q1', id: 'q1-A', title: 'String' },
  { questionId: 'q1', id: 'q1-B', title: 'Array' },
  { questionId: 'q1', id: 'q1-C', title: 'Number' },
  { questionId: 'q1', id: 'q1-D', title: 'Undefined' },
  { questionId: 'q1', id: 'q1-E', title: 'Null' },
  { questionId: 'q1', id: 'q1-F', title: 'Boolean' },
  { questionId: 'q1', id: 'q1-G', title: 'Object' },
  {
    questionId: 'q2',
    id: 'q2-A',
    title:
      'Transactions should be atomic. An entire transaction should process to update the database.'
  },
  {
    questionId: 'q2',
    id: 'q2-B',
    title:
      'Transactions should be durable. A database should not revert to a previous state.'
  },
  {
    questionId: 'q2',
    id: 'q2-C',
    title:
      'Transactions should be consistent. Ledgers should be updated in a reliable, permitted way.'
  },
  {
    questionId: 'q2',
    id: 'q2-D',
    title:
      'Transactions should be conditional. They should only be processed for some users, some of the time. '
  },
  {
    questionId: 'q3',
    id: 'q3-A',
    title: 'They do not rely on a central authority to maintain data.'
  },
  {
    questionId: 'q3',
    id: 'q3-B',
    title: 'They do not require data durability.'
  },
  {
    questionId: 'q3',
    id: 'q3-C',
    title: 'They do not require atomicity in transactions.'
  },
  {
    questionId: 'q3',
    id: 'q3-D',
    title: 'They allow for faster database transactions.'
  },
  {
    questionId: 'q4',
    id: 'q4-A',
    title: 'To allow a database to updated very quickly'
  },
  {
    questionId: 'q4',
    id: 'q4-B',
    title: 'Facilitate agreement among participants of a distributed system'
  },
  {
    questionId: 'q4',
    id: 'q4-C',
    title: 'To protect user data'
  }
]

import React from 'react'

import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from './Icon'

import { VARIANT_COLOR_PALETTE_KEYS } from '../../styles/ColorPalette'
import { ICONS } from '../../styles/IconLibrary'
import { buttonStyles } from '../../styles/Shared'

// 10/10 Will recieve pitchforks for this
// And I deserve it. I do. I absolutely do.
// ^ leaving this as a sign of hope
// it's ok to cause max(pain) on the *first* iteration
const AnchorStyled = styled(motion.a)`
  border-radius: 25px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: self-end;
  justify-content: center;
  gap: 0.5em;
  border-radius: 50px;
  vertical-align: top;
  font-size: 1rem;
  padding: 0.6em 1em 0.65em;
  line-height: 1.2;
  user-select: none;
  &:hover {
    text-decoration: none;
  }
  img {
    user-select: none;
    height: 1rem;
    width: 1rem;
  }
  ${buttonStyles}
`

const ButtonStyled = styled(motion.button)`
  background-color: transparent;
  border: none;
  user-select: none;
  justify-content: center;
  ${buttonStyles}
`

const Button = ({
  icon,
  iconPos = 'left',
  label,
  handleClick,
  variant,
  colorscheme = 'default',
  isDisabled,
  role = 'button',
  url,
  ...props
}) => {
  const iconProps = {}
  if (variant === 'primary') {
    if (colorscheme === 'default') {
      iconProps.color = 'white'
    } else {
      iconProps.color = 'default'
    }
  }
  if (variant === 'secondary') {
    iconProps.color = colorscheme
  }
  if (variant === 'tertiary') {
    if (colorscheme === 'default') {
      iconProps.color = 'default'
    } else {
      iconProps.color = 'white'
    }
  }
  return role === 'button' ? (
    <ButtonStyled
      role='button'
      {...props}
      variant={variant}
      onClick={handleClick}
      disabled={isDisabled}
      colorscheme={colorscheme}
      whileTap={!isDisabled && { opacity: 0.4 }}
      whileHover={!isDisabled && { opacity: 0.7 }}
    >
      {ICONS[icon] && iconPos === 'left' && <Icon name={icon} {...iconProps} />}
      {label}
      {ICONS[icon] && iconPos === 'right' && (
        <Icon name={icon} {...iconProps} />
      )}
    </ButtonStyled>
  ) : (
    <AnchorStyled
      {...props}
      variant={variant}
      href={url}
      rel='noopener noreferrer'
      target='_blank'
      disabled={isDisabled}
      colorscheme={colorscheme}
      whileTap={!isDisabled && { opacity: 0.4 }}
      whileHover={!isDisabled && { opacity: 0.7 }}
    >
      {ICONS[icon] && <Icon name={icon} />}
      {label}
    </AnchorStyled>
  )
}

export default Button

Button.defaultProps = {
  url: null,
  handleClick: null,
  colorscheme: 'default'
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  role: PropTypes.oneOf(['button', 'link']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', null]),
  handleClick: PropTypes.func,
  url: PropTypes.string,
  // Do the props thing here
  icon: PropTypes.oneOf(Object.keys(ICONS)),
  iconPos: PropTypes.oneOf(['left', 'right']),
  isDisabled: PropTypes.bool,
  colorscheme: PropTypes.oneOf(VARIANT_COLOR_PALETTE_KEYS)
}

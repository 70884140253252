// WIP
// Social Media Icons
import { ReactComponent as Discord } from '../assets/logos/discord.svg'
import { ReactComponent as GitHubInverted } from '../assets/logos/github-inverted.svg'
import { ReactComponent as GitHub } from '../assets/logos/github.svg'
import { ReactComponent as Gnosis } from '../assets/logos/gnosis-chain.svg'
import { ReactComponent as LinkedIn } from '../assets/logos/linkedin.svg'
import { ReactComponent as Medium } from '../assets/logos/medium.svg'
import { ReactComponent as MetaMask } from '../assets/logos/metamask.svg'
import { ReactComponent as OpenSea } from '../assets/logos/opensea.svg'
import { ReactComponent as Twitter } from '../assets/logos/twitter.svg'
// SVG Icons
import { ReactComponent as AlertCircle } from '../assets/svg/alert-circle.svg'
import { ReactComponent as Arrow } from '../assets/svg/arrow.svg'
import { ReactComponent as Book } from '../assets/svg/book.svg'
import { ReactComponent as Circle } from '../assets/svg/circle.svg'
import { ReactComponent as CrossCircle } from '../assets/svg/cross-circle.svg'
import { ReactComponent as Cross } from '../assets/svg/cross.svg'
import { ReactComponent as Download } from '../assets/svg/download.svg'
import { ReactComponent as Email } from '../assets/svg/email.svg'
import { ReactComponent as External } from '../assets/svg/external-link.svg'
import { ReactComponent as Globe } from '../assets/svg/globe.svg'
import { ReactComponent as Help } from '../assets/svg/help.svg'
import { ReactComponent as LearningIcon } from '../assets/svg/learning.svg'
import { ReactComponent as Lock } from '../assets/svg/lock.svg'
import { ReactComponent as Multimedia } from '../assets/svg/multimedia.svg'
import { ReactComponent as Cube } from '../assets/svg/nfts/cube.svg'
import { ReactComponent as Module0 } from '../assets/svg/nfts/module-0.svg'
import { ReactComponent as Module1 } from '../assets/svg/nfts/module-1.svg'
import { ReactComponent as Module10 } from '../assets/svg/nfts/module-10.svg'
import { ReactComponent as Module2 } from '../assets/svg/nfts/module-2.svg'
import { ReactComponent as Module3 } from '../assets/svg/nfts/module-3.svg'
import { ReactComponent as Module4 } from '../assets/svg/nfts/module-4.svg'
import { ReactComponent as Module5 } from '../assets/svg/nfts/module-5.svg'
import { ReactComponent as Module6 } from '../assets/svg/nfts/module-6.svg'
import { ReactComponent as Module7 } from '../assets/svg/nfts/module-7.svg'
import { ReactComponent as Module8 } from '../assets/svg/nfts/module-8.svg'
import { ReactComponent as Module9 } from '../assets/svg/nfts/module-9.svg'
import { ReactComponent as Pause } from '../assets/svg/pause.svg'
import { ReactComponent as Play } from '../assets/svg/play.svg'
import { ReactComponent as Polygon } from '../assets/svg/polygon.svg'
import { ReactComponent as Send } from '../assets/svg/send.svg'
import { ReactComponent as SuccessCircle } from '../assets/svg/success-circle.svg'
import { ReactComponent as Terminal } from '../assets/svg/terminal.svg'
import { ReactComponent as Trophy } from '../assets/svg/trophy.svg'
import { ReactComponent as User } from '../assets/svg/user.svg'
import { ReactComponent as Video } from '../assets/svg/video.svg'

export const ICONS = {
  'alert circle': {
    icon: AlertCircle,
    alt: 'Alert Circle'
  },
  arrow: {
    icon: Arrow,
    alt: 'arrow'
  },
  article: {
    icon: Book,
    alt: 'book'
  },
  blockscout: {
    icon: Gnosis,
    alt: 'gnosis'
  },
  'code exercise': {
    icon: Terminal,
    alt: 'terminal'
  },
  circle: {
    icon: Circle,
    alt: 'circle'
  },
  globe: {
    icon: Globe,
    alt: 'globe'
  },
  learningIcon: {
    icon: LearningIcon,
    alt: 'learning'
  },
  multimedia: {
    icon: Multimedia,
    alt: 'multimedia'
  },
  polygon: {
    icon: Polygon,
    alt: 'polygon'
  },
  successCircle: {
    icon: SuccessCircle,
    alt: 'success'
  },
  trophy: {
    icon: Trophy,
    alt: 'trophy'
  },
  user: {
    icon: User,
    alt: 'user'
  },
  discord: {
    icon: Discord,
    alt: 'discord'
  },
  download: {
    icon: Download,
    alt: 'Download'
  },
  email: {
    icon: Email,
    alt: 'email'
  },
  close: {
    icon: CrossCircle,
    alt: 'close'
  },
  exit: {
    icon: Cross,
    alt: 'exit'
  },
  external: {
    icon: External,
    alt: 'external'
  },
  github: {
    icon: GitHub,
    alt: 'github'
  },
  github_inverted: {
    icon: GitHubInverted,
    alt: 'github'
  },
  help: {
    icon: Help,
    alt: 'help'
  },
  linkedIn: {
    icon: LinkedIn,
    alt: 'linkedin'
  },
  locked: {
    icon: Lock,
    alt: 'lock'
  },
  medium: {
    icon: Medium,
    alt: 'medium'
  },
  metamask: {
    icon: MetaMask,
    alt: 'MetaMask'
  },
  opensea: {
    icon: OpenSea,
    alt: 'OpenSea'
  },
  pause: {
    icon: Pause,
    alt: 'pause'
  },
  play: {
    icon: Play,
    alt: 'play'
  },
  send: {
    icon: Send,
    alt: 'send'
  },
  twitter: {
    icon: Twitter,
    alt: 'twitter'
  },
  video: {
    icon: Video,
    alt: 'video'
  },
  // NFTs
  cube: {
    icon: Cube,
    alt: 'cube'
  },
  'module-0': {
    icon: Module0,
    alt: 'Introduction'
  },
  'module-1': {
    icon: Module1,
    alt: 'Module 1'
  },
  'module-2': {
    icon: Module2,
    alt: 'Module 2'
  },
  'module-3': {
    icon: Module3,
    alt: 'Module 3'
  },
  'module-4': {
    icon: Module4,
    alt: 'Module 4'
  },
  'module-5': {
    icon: Module5,
    alt: 'Module 5'
  },
  'module-6': {
    icon: Module6,
    alt: 'Module 6'
  },
  'module-7': {
    icon: Module7,
    alt: 'Module 7'
  },
  'module-8': {
    icon: Module8,
    alt: 'Module 8'
  },
  'module-9': {
    icon: Module9,
    alt: 'Module 9'
  },
  'module-10': {
    icon: Module10,
    alt: 'Module 10'
  }
}

import styled, { css } from 'styled-components'

import { DEFAULT_COLOR_PALETTE } from '../../../styles/ColorPalette'
import { SIZE } from '../../../styles/Size'

const { background, text } = DEFAULT_COLOR_PALETTE.grayScales

export const Container = styled.div`
  display: flex;
  gap: 64px;
  align-items: center;
  & > img {
    display: none;
  }

  @media screen and (max-width: ${SIZE.mobile}) {
    display: grid;
    grid-template-rows: ${({ connected }) =>
      css`1fr ${connected ? '85px' : '34px'}`};
    gap: 32px;
    order: -1;
    padding: ${({ connected }) =>
      css`
        ${connected ? '16px' : '24px'} 16px
      `};
    height: 178px;
    max-height: 178px;
    & > img {
      display: block;
      height: 100%;
      margin: 0 auto;
    }
    & > button {
      color: ${text.primary};
      background-color: ${background.disabled};
      width: 100%;
      font-size: 12px;
    }
    button[name='signout'] {
      display: none;
    }
  }
`

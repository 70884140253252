import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

function SuggestMobile({ isOpen, handleClose }) {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='orange'>
      <h3>Mobile detected</h3>
      <p>
        We detected you are using a mobile device. For a better experience, we
        suggest you switch to a desktop device.
      </p>
      <Button
        colorscheme='white'
        variant='secondary'
        label='Close'
        handleClick={handleClose}
      />
    </Modal>
  )
}

SuggestMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default SuggestMobile

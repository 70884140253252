import React, { useState } from 'react'

import { Link, useLocation } from 'react-router-dom'

import { Brand, Burger, CloseButton, Navbar, NavbarMenu } from './Header.style'

import rubiks from '../../../assets/brand/rubiks-transparent.svg'
import { ReactComponent as BurgerIcon } from '../../../assets/svg/burger.svg'
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross-circle.svg'
import Icon from '../../atoms/Icon'
import NavLink from '../../atoms/NavLink'
import AuthStatus from '../../molecules/AuthStatus'

const links = [
  {
    desktop: true,
    path: '/about',
    label: 'About'
  },
  {
    desktop: false,
    path: '',
    label: 'Decentralized Mechanics'
  },
  {
    desktop: false,
    path: '',
    label: 'Our Course'
  },
  {
    desktop: true,
    path: 'https://discord.com',
    label: 'Discord',
    icon: <Icon name='discord' role='img' />
  },
  {
    desktop: true,
    path: 'https://twitter.com',
    label: 'Twitter',
    icon: <Icon name='twitter' role='img' />
  },
  {
    desktop: false,
    path: '',
    label: 'Contribute'
  },
  {
    desktop: false,
    path: '',
    label: 'Support',
    icon: <Icon name='alert circle' role='img' />
  }
]

const Header = () => {
  const location = useLocation()
  const [isMobileExpanded, setMobileExpanded] = useState(false)

  return (
    <Navbar data-testid='header'>
      <Brand>
        <Link to={'/'}>
          <img src={rubiks} alt='Education DAO' />
        </Link>
      </Brand>
      <Burger onClick={() => setMobileExpanded(true)}>
        <BurgerIcon />
      </Burger>
      <NavbarMenu expanded={isMobileExpanded} aria-expanded={isMobileExpanded}>
        <CloseButton onClick={() => setMobileExpanded(false)}>
          <CloseIcon />
        </CloseButton>
        <ul>
          {links.map(({ path, label, icon, desktop }) => (
            <NavLink
              key={path}
              desktop={desktop}
              path={path}
              active={location.pathname === path}
            >
              {icon}
              <label>{label}</label>
            </NavLink>
          ))}
          <AuthStatus />
        </ul>
      </NavbarMenu>
    </Navbar>
  )
}

export default Header

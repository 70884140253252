// Need to add Modules

/**
 * @typedef {string} LessonType
 */

/**
 * @enum {LessonType}
 */
export const LESSON_TYPE = {
  TEXT: 'text',
  VIDEO: 'video',
  WORKSPACE: 'workspace',
  QUIZ: 'quiz'
}

/**
 * @typedef {Object} Lesson
 * @property {string} id - Lesson's ID
 * @property {string} title - Lesson's Title
 * @property {string} slug - Slug is used in order to retrieve lesson content from github
 * @property {LessonType} type
 */
/**
 * @typedef {Object} Module
 * @property {string} id - Module's ID
 * @property {string} title - Module's Title
 * @property {string} slug
 * @property {Array.<Lesson>} lessons - Module's lessons
 */
export const MODULES = [
  {
    id: 'introduction',
    // Needed to check the minted NFT from SmartContract
    moduleId: '8',
    title: 'Introduction',
    lessons: [
      {
        id: 'why-learn-blockchain',
        type: LESSON_TYPE.TEXT,
        slug: 'S00-intro/L2-why-learn',
        title: 'Why Learn Blockchain?'
      },
      {
        id: 'tips-for-this-course',
        type: LESSON_TYPE.TEXT,
        slug: 'S00-intro/L3-course-tips',
        title: 'Tips For This Course'
      },
      {
        id: 'technical-requirements',
        type: LESSON_TYPE.TEXT,
        slug: 'S00-intro/L4-technical-requirements',
        title: 'Technical Requirements'
      },
      {
        id: 'for-advanced-students',
        type: LESSON_TYPE.TEXT,
        slug: 'S00-intro/L7-advanced-students',
        title: 'For Advanced Students'
      }
    ]
  },
  {
    id: 'fundamentals',
    // Needed to check the minted NFT from SmartContract
    moduleId: '2',
    title: 'Fundamentals',
    lessons: [
      {
        id: 'a-paper-blockchain',
        type: LESSON_TYPE.VIDEO,
        slug: 'S01-fundamentals/',
        title: 'A Paper Blockchain',
        url: 'https://drive.google.com/file/d/1j9xrh1a3Dx1lbd0XnY_mgy67mmQVc5eu/preview?usp=sharing'
      },
      {
        id: 'introduction-fundamentals',
        type: LESSON_TYPE.TEXT,
        slug: 'S01-fundamentals/M0-intro',
        title: 'Introduction to Fundamentals'
      },
      {
        id: 'the-quiz',
        type: 'quiz',
        title: LESSON_TYPE.QUIZ,
        questions: [
          {
            id: 'q1',
            title:
              'Imagine a group of 15 financial institutions, each of which operates a node in the blockchain and of which 10 must sign every block in order for the block to be valid. The right to read the blockchain may be public, or restricted to the participants. This is an example of what kind of blockchain?',
            multiple: false
          },
          {
            id: 'q2',
            title:
              'Which of the following are important features of distributed database transactions? Select all that apply',
            multiple: true
          },
          {
            id: 'q3',
            title:
              'How are distributed ledgers different than traditional ledgers? Choose one',
            multiple: false
          },
          {
            id: 'q4',
            title: 'What is the goal of a consensus mechanism? Choose one',
            multiple: false
          }
        ]
      }
    ]
  }
]

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import gigaBrain from '../../assets/brand/giga-brain-v1.svg'

const Main = styled.main`
  ${
    '' /* Remember to check the padding in the footer to see where this overlaps */
  }
  width: 50vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  h1 {
    text-align: center;
  }
  img {
    user-select: none;
    width: 40%;
    height: auto;
  }
`

const Page404 = () => {
  return (
    <Main>
      <img src={gigaBrain} alt='' />
      <h1>Oops, this page doesn&apos;t exist.</h1>
      <p>
        <Link to={'/'}>Go back home.</Link>
      </p>
    </Main>
  )
}

export default Page404

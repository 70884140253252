import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { default as SvgIcon } from './Icon'

import { ICONS } from '../../styles/IconLibrary'

const Icon = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  img {
    height: 25px;
    width: 25px;
  }
`

// Perhaps add props for sizing
// Sm -> 25px
// Md -> 30px
const IconButton = ({ icon, handleClick, ...props }) => {
  return (
    // Propagate props from parent component. It's usefull for styling
    <Icon onClick={handleClick} {...props}>
      {ICONS[icon] && <SvgIcon name={icon} {...props} />}
    </Icon>
  )
}

export default IconButton

IconButton.propTypes = {
  icon: PropTypes.string,
  handleClick: PropTypes.func
}

import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import LinkButton from '../atoms/LinkButton'
import Modal from '../organisms/Modal'

const Whitelist = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='orange'>
      <h3>Thank you for your interest!</h3>
      <p>
        Our 2022 cohort is currently closed. If you’d like to sign-up for next
        year’s cohort, you can join the list in the link below.
      </p>
      <LinkButton
        colorscheme='orange'
        variant='primary'
        label='Join the list'
        href={process.env.REACT_APP_JOIN_LIST_URL}
      />
      <Button
        colorscheme='orange'
        variant='secondary'
        label='Cancel'
        handleClick={handleClose}
      />
    </Modal>
  )
}
Whitelist.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default Whitelist

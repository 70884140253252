import { transparentize } from 'polished'

export const DEFAULT_COLOR_PALETTE = {
  grayScales: {
    text: {
      primary: '#F1F1F1',
      secondary: '#C6C6C6',
      tertiary: '#121212',
      disabled: '#7D7676'
    },
    background: {
      primary: '#121212',
      secondary: '#1A1A1A',
      tertiary: '#292929',
      disabled: '#434343'
    }
  },
  palette: {
    default: {
      primary: '#121212',
      hover: `${transparentize(0.4, '#121212')}`,
      pressed: `${transparentize(0.1, '#121212')}`,
      background: {
        default: `${transparentize(0.7, '#121212')}`,
        hover: `${transparentize(0.8, '#121212')}`
      }
    },
    white: {
      primary: '#F1F1F1',
      hover: `${transparentize(0.4, '#F1F1F1')}`,
      pressed: `${transparentize(0.1, '#F1F1F1')}`,
      background: {
        default: `${transparentize(0.7, '#F1F1F1')}`,
        hover: `${transparentize(0.8, '#F1F1F1')}`
      }
    },
    grey: {
      primary: '#7D7676',
      hover: `${transparentize(0.4, '#7D7676')}`,
      pressed: `${transparentize(0.1, '#7D7676')}`,
      background: {
        default: `${transparentize(0.7, '#7D7676')}`,
        hover: `${transparentize(0.8, '#7D7676')}`
      }
    },
    purple: {
      primary: '#A798EF',
      hover: `${transparentize(0.4, '#A798EF')}`,
      pressed: `${transparentize(0.1, '#A798EF')}`,
      background: {
        default: `${transparentize(0.7, '#A798EF')}`,
        hover: `${transparentize(0.8, '#A798EF')}`
      }
    },
    red: {
      primary: '#E66E7C',
      hover: `${transparentize(0.4, '#E66E7C')}`,
      pressed: `${transparentize(0.1, '#E66E7C')}`,
      background: {
        default: `${transparentize(0.7, '#E66E7C')}`,
        hover: `${transparentize(0.8, '#E66E7C')}`
      }
    },
    yellow: {
      primary: '#E9AC65',
      hover: `${transparentize(0.4, '#E9AC65')}`,
      pressed: `${transparentize(0.1, '#E9AC65')}`,
      background: {
        default: `${transparentize(0.7, '#E9AC65')}`,
        hover: `${transparentize(0.8, '#E9AC65')}`
      }
    },
    orange: {
      primary: '#E68566',
      hover: `${transparentize(0.4, '#E68566')}`,
      pressed: `${transparentize(0.1, '#E68566')}`,
      background: {
        default: `${transparentize(0.7, '#E68566')}`,
        hover: `${transparentize(0.8, '#E68566')}`
      }
    },
    green: {
      primary: '#58B090',
      hover: `${transparentize(0.4, '#58B090')}`,
      pressed: `${transparentize(0.1, '#58B090')}`,
      background: {
        default: `${transparentize(0.7, '#58B090')}`,
        hover: `${transparentize(0.8, '#58B090')}`
      }
    },
    skyBlue: {
      primary: '#72B7CC',
      hover: `${transparentize(0.4, '#72B7CC')}`,
      pressed: `${transparentize(0.1, '#72B7CC')}`,
      background: {
        default: `${transparentize(0.7, '#72B7CC')}`,
        hover: `${transparentize(0.8, '#72B7CC')}`
      }
    },
    blue: {
      primary: '#6C9BE1',
      hover: `${transparentize(0.4, '#6C9BE1')}`,
      pressed: `${transparentize(0.1, '#6C9BE1')}`,
      background: {
        default: `${transparentize(0.7, '#6C9BE1')}`,
        hover: `${transparentize(0.8, '#6C9BE1')}`
      }
    },
    pink: {
      primary: '#D887C2',
      hover: `${transparentize(0.4, '#D887C2')}`,
      pressed: `${transparentize(0.1, '#D887C2')}`,
      background: {
        default: `${transparentize(0.7, '#D887C2')}`,
        hover: `${transparentize(0.8, '#D887C2')}`
      }
    }
  }
}

export const VARIANT_COLOR_PALETTE_KEYS = Object.keys(
  DEFAULT_COLOR_PALETTE.palette
)

export const VARIANT_COLOR_PALETTE = VARIANT_COLOR_PALETTE_KEYS.reduce(
  (previousValue, currentValue) => ({
    ...previousValue,
    [currentValue]: currentValue
  }),
  {}
)

import React from 'react'

import { getDefaultProvider } from 'ethers'
import { createRoot } from 'react-dom/client'
import { WagmiConfig, createClient } from 'wagmi'

import App from './App'
import { NotificationProvider } from './hooks/useNotification'
import { SessionProvider } from './hooks/useSession'
import reportWebVitals from './reportWebVitals'

const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider()
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <WagmiConfig client={client}>
    <NotificationProvider>
      <SessionProvider>
        <App />
      </SessionProvider>
    </NotificationProvider>
  </WagmiConfig>
)

if (process.env.REACT_APP_MOCK === 'true') {
  const { worker } = require('./mocks/msw-worker')
  worker.start({
    onUnhandledRequest: 'bypass'
  })
}

reportWebVitals()

import PropTypes from 'prop-types'

import Button from '../atoms/Button'
import Modal from '../organisms/Modal'

const TransactionInitiated = ({ isOpen, handleClose, message }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} variant='purple'>
      <h3>Transaction Initiated</h3>
      <p>{message}</p>
      <Button
        variant='primary'
        colorscheme='purple'
        label='Close'
        handleClick={handleClose}
      />
    </Modal>
  )
}

TransactionInitiated.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  message: PropTypes.string
}

export default TransactionInitiated
